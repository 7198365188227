import React from 'react';

import Link from '/src/components/LocalizedLink';
import Layout from '../components/Layout';
import Faq from '../components/Faq';
import ReviewsSection from '../components/ReviewsSection';

import useLocale from '../hooks/useLocale';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';

import imgRmain from '../assets/images/accomodation/Photo 1 (4).png';
import imgDots from '../assets/images/application-process/Dots 3.svg';

import '../assets/styles/pages/accomodation-page.scss';
import { FormattedMessage } from 'react-intl';

const faqs = [
  {
    id: 1,
    title: 'How much will it cost per month?',
    description: `
      <p>Our Barcelona rooms cost 750€ for the 4 weeks of your course. There are a couple of cheaper alternatives, subject to availability.</p>
      <br/>
      <br/>
      <p>You can also save time and money by signing up to 12 weeks of accommodation and guaranteeing that you have accommodation organised after your course. It can be stressful to search for accommodation during your course, so this option will give you peace of mind! 12 weeks of accommodation costs 1950€, a saving of 300€. </p>
      `,
  },
  {
    id: 2,
    title: 'Where are the flats?',
    description:
      'Various locations around Barcelona, but all very central and within 30min walking distance / public transport of the school.',
  },
  {
    id: 3,
    title: 'Who will I be sharing with?',
    description:
      'We normally house you with other students, ex-students, or locals living here. Great for first-hand advice on living and working in Barcelona.',
  },
  {
    id: 4,
    title: 'What is the accommodation like?',
    description:
      'You’ll be in a shared apartment, living with 2-3 other people, with all the facilities you’d expect from a modern flat. You’ll have space to study, bedding, privacy, wifi, use of all the kitchen and a shared bathroom.',
  },
  {
    id: 5,
    title: 'When can I move in?',
    description:
      'We organise all check-ins for the Sunday before your course start date, and check-outs on the Saturday after you finish. If you wish to arrive earlier we can share some hotel or hostel recommendations. It might be possible to extend your stay once your course finishes, depending on availability.',
  },
  {
    id: 6,
    title: 'Can I stay with a friend / partner? Can I bring children?',
    description: `Yes, it is possible to make special arrangements for two or more people, please email us separately at coordination@tefl-iberia.com with details.`,
  },
  {
    id: 7,
    title: 'Are bills included?',
    description: `Yes, all bills are included. Electricity, gas, water, wifi, etc are included. Food, meals and public transport are not included.`,
  },
  {
    id: 8,
    title: 'Do I have to pay a security deposit?',
    description: `Yes, you will be required to pay a refundable security deposit upon arrival, preferably in cash, which will be returned to you when you leave. It is typically 200€ but some hosts require more. We will confirm these details with you before you arrive. `,
  },
  {
    id: 9,
    title: 'Can I have guests?',
    description: `Normally no, but it is possible to come to a private arrangement with your host.`,
  },
];

const faqsEs = [
  {
    id: 1,
    title: '¿Cuánto costará por mes?',
    description:
      'Nuestras habitaciones en Barcelona cuestan 750 € por las 4 semanas de tu curso. Hay un par de alternativas más económicas, sujetas a disponibilidad. <br/><br/> Nuestras habitaciones en Sevilla cuestan 550 € por las 4 semanas de tu curso.',
  },
  {
    id: 2,
    title: '¿Dónde están los apartamentos?',
    description:
      'Varios lugares en Barcelona y Sevilla, todos muy céntricos y a menos de 30 minutos a pie o en transporte público de la escuela.',
  },
  {
    id: 3,
    title: '¿Con quién compartiré alojamiento?',
    description:
      'Normalmente te alojaremos con otros estudiantes, ex estudiantes o personas locales que viven aquí. Esto es genial para recibir consejos de primera mano sobre vivir y trabajar en Barcelona o Sevilla.',
  },
  {
    id: 4,
    title: '¿Cómo es el alojamiento?',
    description:
      'Estarás en un apartamento compartido, viviendo con 2-3 personas más, con todas las comodidades que esperarías de un piso moderno. Tendrás espacio para estudiar, ropa de cama, privacidad, wifi, uso de la cocina y un baño compartido.',
  },
  {
    id: 5,
    title: '¿Cuándo puedo mudarme?',
    description: `Normalmente organizamos que te mudes el sábado o domingo antes de la fecha de inicio de tu curso y te mudes el domingo después de terminar. También es posible extender la estancia antes o después de las fechas del curso, solo avísanos en el recuadro de 'información adicional'.`,
  },
  {
    id: 6,
    title: '¿Puedo quedarme con un amigo / pareja? ¿Puedo traer niños?',
    description: `Sí, es posible hacer arreglos especiales para dos o más personas, por favor envíanos un correo electrónico a <a calss="red-link" href="mailto:accommodation@tefl-iberia.com">accommodation@tefl-iberia.com</a> con los detalles.`,
  },
  {
    id: 7,
    title: '¿Están incluidos los gastos?',
    description: `Sí, todas las facturas están incluidas. Electricidad, gas, agua, wifi, etc. están incluidos. La comida, las comidas y el transporte público no están incluidos.`,
  },
  {
    id: 8,
    title: '¿Tengo que pagar un depósito de seguridad?',
    description: `Sí, se requerirá que pagues un depósito de seguridad reembolsable al llegar, preferiblemente en efectivo, que te será devuelto cuando te vayas. Normalmente es de 200 €, pero algunos anfitriones pueden requerir más. Confirmaremos estos detalles contigo antes de que llegues.`,
  },
  {
    id: 9,
    title: '¿Puedo tener invitados?',
    description: `Normalmente no, pero es posible llegar a un acuerdo privado con tu anfitrión.`,
  },
];

const Accomodation = () => {
  const { enLocale } = useLocale({ matchPathName: '/es/' });
  return (
    <Layout
      title={{ id: 'accommodation.seo.title' }}
      description={{ id: 'accommodation.seo.description' }}
    >
      <div className="accomodation-page">
        <section className="first-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 ">
                <h1 className="c-title-42 first-section__title">
                  <FormattedMessage id="accommodation.sec1.title" />
                </h1>
              </div>
              <div className="col-md-6">
                <p className="c-text-18 first-section__description">
                  {/* If the thought of moving to another city and negotiating with foreign landlords
                  sounds stressful, don’t worry! We can provide you with
                  <strong>comfortable, excellent-value accommodation</strong> within easy reach of
                  the school. Our accommodation placement service is free and optional. */}
                  <FormattedMessage
                    id="accommodation.sec1.description"
                    values={{
                      accent: (chunk) => <strong>{chunk}</strong>,
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="second-section  c-section-py">
          <div className="second-section__wrapper c-faqs-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-7 col-12">
                  <div className="left">
                    <h3 className="c-title-34">
                      <FormattedMessage id="common.faqs" />
                    </h3>
                    {faqs.map((_, index) => (
                      <Faq
                        description={enLocale ? faqs[index].description : faqsEs[index].description}
                        title={enLocale ? faqs[index].title : faqsEs[index].title}
                      />
                    ))}
                    {/* <Faq
                      description={enLocale ? faqs[0].description : faqsEs[0].description}
                      title={enLocale ? faqs[0].title : faqsEs[0].title}
                    />
                    <Faq
                      description={enLocale ? faqs[1].description : faqsEs[1].description}
                      title={enLocale ? faqs[1].title : faqsEs[1].title}
                    />
                    <Faq
                      description={enLocale ? faqs[2].description : faqsEs[2].description}
                      title={enLocale ? faqs[2].title : faqsEs[2].title}
                    />
                    <Faq
                      description={enLocale ? faqs[3].description : faqsEs[3].description}
                      title={enLocale ? faqs[3].title : faqsEs[3].title}
                    />
                    <Faq
                      description={enLocale ? faqs[4].description : faqsEs[4].description}
                      title={enLocale ? faqs[4].title : faqsEs[4].title}
                    />
                    <Faq
                      description={enLocale ? faqs[5].description : faqsEs[5].description}
                      title={enLocale ? faqs[5].title : faqsEs[5].title}
                    />
                    <Faq
                      description={enLocale ? faqs[6].description : faqsEs[6].description}
                      title={enLocale ? faqs[6].title : faqsEs[6].title}
                    />
                    <Faq
                      description={enLocale ? faqs[7].description : faqsEs[7].description}
                      title={enLocale ? faqs[7].title : faqsEs[7].title}
                    />
                    <Faq
                      description={enLocale ? faqs[8].description : faqsEs[8].description}
                      title={enLocale ? faqs[8].title : faqsEs[8].title}
                    /> */}
                    {/* <Link to="/apply/" className="c-btn c-btn--red">
                      Sign Up Now
                      <IconArrowRight />
                    </Link> */}
                    <FormattedMessage id="url.apply.index">
                      {(locale) => (
                        <Link to={locale[0]} className="c-btn c-btn--red">
                          <FormattedMessage id="menu.signUpNow" />
                          <IconArrowRight />
                        </Link>
                      )}
                    </FormattedMessage>
                  </div>
                </div>
                <div className="col-lg-6 col-md-5 col-12">
                  <div className="right">
                    <img src={imgRmain} alt="Frequently Asked Questions" className="right__img" />
                    <img src={imgDots} alt="dots" className="dots" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="third-section">
          <ReviewsSection hideAllReviews title={{ id: 'graduates.testimonials' }} />
        </section>
      </div>
    </Layout>
  );
};

export default Accomodation;
